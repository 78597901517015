<template>
   <div class="content">
        <div class="tool">
            <el-button type="primary" class="el-icon-plus" style="margin-right:20px" @click="AddClick"> 新增</el-button>
            <el-input placeholder="请输入名称" v-model="searchForm.searchInfo" style="width:200px;">
                <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
            </el-input>
        </div>

        <el-table :data='DataList' border stripe :highlight-current-row='true' max-height='calc(100vh - 160px)' height='calc(100vh - 160px)'>
            <el-table-column align="center" prop="sp_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="sp_addr" label="地址"></el-table-column>
            <el-table-column align="center" prop="sp_contacts" label="联系人"></el-table-column>
            <el-table-column align="center" prop="sp_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="sp_schemaId" label="服务商账号"></el-table-column>
            <el-table-column align="center" prop="sp_ukey" label="服务商秘钥"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                        <el-link type="warning" @click="EditUser(scope.row)">操作员</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" @current-change="current_change" :total="total" background :page-size="searchForm.pageSize"></el-pagination>
        

        <!--服务商-->
         <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
            <el-form :model="serForm" :rules="rules" ref="serForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="sp_name">
                    <el-input v-model="serForm.sp_name"></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="serForm.sp_addr"></el-input>
                </el-form-item>
                <el-form-item label="联系人">
                  <el-input v-model="serForm.sp_contacts"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" >
                  <el-input v-model="serForm.sp_phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <!--操作员-->
        <el-dialog v-if="serUserDialogVisible" :title="title" :visible.sync="serUserDialogVisible" width='80%' :append-to-body='true'>
            <SerUser :beglongId='SerId' :userType="1"/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="serUserDialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
import util from '../../util/util'
import SerUser from '../../components/servicer/SerUser.vue'

export default{
    data:function(){
        return{
            dialogVisible: false,
            title: '',

            searchForm:{
                searchInfo:'',
                pageNo:1,
                pageSize:15,
            },
            total:0,

            serUserDialogVisible: false,

            SerId:0,
            DataList: [],
            serForm: {
                sp_id: 0,
                sp_name: '',
                sp_addr: '',
                sp_phone: '',
                sp_contacts: ''
            },
            rules: {
                sp_name: [{ required: true, message: '请输入服务商名称', trigger: 'blur' }]
            },
        }
    },
    components: {
        SerUser
    },
    created(){
        
        this.GetDataList()
    },
    methods:{
        GetDataList () {
            util.Get('serprod/getserproddatalist',this.searchForm).then(res => {
                if (res.rpStatus === 10000) {
                    this.DataList = res.list
                    this.total=res.total
                }
            })
        },
        AddClick(){
            this.dialogVisible=true
            this.title="新增"
            this.serForm.sp_id=0
            this.serForm.sp_name = ''
            this.serForm.sp_addr = ''
            this.serForm.sp_phone = ''
            this.serForm.sp_contacts = ''
        },
        Edit(row){
            this.dialogVisible=true
            this.title="修改"
            this.serForm=JSON.parse(JSON.stringify(row))
        },
        submit(){
            this.$refs.serForm.validate((valid) =>{
                if (valid) {
                    util.Post('serprod/edit',this.serForm).then(res => {
                        if (res.rpStatus === 10000) {
                            this.GetDataList()
                            this.dialogVisible=false
                        }
                    })
                }
            })
        },
        Search(){
            this.GetDataList()
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.GetDataList()
        },
        EditUser(row){
            this.serUserDialogVisible=true
            this.title="操作员"
            this.SerId=row.sp_id
        }
    }
}

</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-content: start;

}
.el-table{
width: 100%;
font-size:13px;
margin-top:10px;
}
.el-link{
    margin-right: 10px;
    font-size: 10px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>